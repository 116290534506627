.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
}

.inner-container {
  display: grid;
  grid-template-areas:
    "skills"
    "tools";
}

main {
  display: grid;
  grid-template-areas:
    "left"
    "right";
}

.look-online {
  display: none;
}

.skills {
  grid-area: skills;
}

.tools {
  grid-area: tools;
}

.education {
  margin-bottom: 20px;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

.image-carousel {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
  overflow: scroll;
  max-width: 90vw;
}

p {
  margin: 0;
  padding: 0;
}

a {
  color: #078282ff;
}

.header-link {
  color: inherit;
}

.language-toggler {
  margin-bottom: 1rem;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 10px solid #078282ff;
}

button {
  border: none;
  background-color: #078282ff;
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
}
button:hover {
  cursor: pointer;
}
button:focus {
  outline: none;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

h2 {
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5px;
}

h2:after {
  content: "";
  background: #078282ff;
  width: 100%;
  height: 3px;
  display: block;
}

h3 {
  font-size: 0.9em;
  color: #339e66ff;
  padding: 0;
  margin: 10px 0 0 0;
}

h4 {
  font-size: 0.95em;
  text-transform: uppercase;
  padding: 0;
  margin: 10px 0 0 0;
}

h5 {
  margin: 0;
  font-size: 1rem;
}

img {
  border-radius: 30px;
  margin-top: 1rem;
}

address {
  margin: 5px 0;
  font-style: normal;
}

li {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }

  .inner-container {
    grid-template-areas: "skills tools";
    gap: 5px;
  }

  main {
    height: 100vh;
    margin: 0 30px;
    grid-template-areas: "left right";
    grid-template-columns: 40% 59%;
    gap: 20px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }

  .sticky {
    padding-top: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media print {
  .language-toggler {
    display: none;
  }
  .look-online {
    display: block;
    margin-top: 1rem;
  }
  .work-experience > h2 {
    margin-top: 6rem;
  }
  .image-carousel {
    display: none;
  }
}
